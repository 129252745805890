/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../pwasaas/src/style/abstract/variables';
@import '../../../../../pwasaas/src/style/abstract/media';
@import '../../../../../pwasaas/src/style/abstract/button';
@import '../../../../../pwasaas/src/style/abstract/loader';
@import '../../../../../pwasaas/src/style/abstract/icons';
@import '../../../../../pwasaas/src/style/abstract/image';
@import '../../../../../pwasaas/src/style/abstract/parts';

:root {
    --saas-header-background-color: var(--primary-base-color);
    --saas-header-usps-background: #E5E5E5;

    @include mobile {
        --saas-header-usps-total-height: 0px;
    }
}

.SaasHeader {
    --search-input-desktop-border-color:#fff;
    --search-field-color: var(--secondary-base-color);

    &-Bottom {
        @include mobile {
            padding-right: 50px !important;
            
            .SearchField-Wrapper {
                .SearchField {
                    &-Input {
                        background-color: #fff !important;
                        padding: 1rem;
                        border: 0.1rem solid #ddd;
                        color: #000;
        
                        &::placeholder {
                            color: #000;
                        }
                    }
                    &-SearchIcon {
                        svg {
                            fill: #061534;
                            height: 3.7rem;
                        }
                    } 
                }
            }
        }
    }

    .Header {
        &-MinicartItemCount{
            background: #A98246 !important;
            color: #fff !important;
        }
    }

    &-Usps {
        .ContentWrapper {
            grid-template-columns: auto;  
            justify-content: center; 
            grid-template-columns: auto 0 !important;
        }

        .SiteUsp{
            &-Icon {
                color: #000;
            }
            &-Item {
                color: #000;
            }
        }
    }

    .SearchField {
        --search-bar-max-width-desktop: 220px;

        margin-right: 40px;

        &-Input {
            --input-background-color: var(--primary-base-color);

            color: var(--search-field-color);
            border: 0;
            border-bottom: 1px solid #fff;
            border-radius: 0;
            font-style: italic;
            caret-color: #fff;

            &::-webkit-input-placeholder{
                color: var(--search-field-color);
            }
        }

        &-CloseIcon,
        &-SearchIcon {
            right: -43px !important;
            width: 40px;
            height: 40px;
            top: calc(50% - 18px) !important;

            svg {
                fill: #fff;
                width: 40px;
                height: 40px;
            }
        }

        &-SearchInnerWrapper {
            > input {
                padding: 1rem 0;
            }
        }

        &-ActionIcon {
            color: var(--search-field-color);

            svg path{
                fill: #fff;
            }
        }
    }

    &-MyAccountWrapper,
    &-MinicartButtonWrapper {
        svg path{
            fill: #fff;
        }
    }

    .MyAccountOverlay {
        top: 6.8rem;
    }

    .CartOverlay {
        top: 6.3rem;

        &-Promo {
            background-color: #FFF;
        }
    }
}